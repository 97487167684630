export const API_SERVICES = {
  AdxQueries: '/api/AdxQueries',
  ArchiveToursRetry: '/api/missionArchive/tours/ArchivedToursByRetryId',
  BrakeTest: '/api/VehicleCommands/braketest',
  Copilot: '/api/copilot',
  Devices: '/api/Features/EvacuationMode/Devices',
  DiscoveryEndpoint: '/api/provider/discovery-endpoint',
  Dispatcher: '/api/Dispatcher',
  ErrorForwarding: '/api/errorForwarding',
  Features: '/api/features',
  FireFighter: '/api/FireFighter',
  Fleets: '/api/Fleets',
  GppMapLayer: '/api/GetMap',
  GraphManager: '/api/GraphManager',
  HuLookupMappings: '/api/pkt/HuLookupMappings',
  InfoBar: '/api/infobar',
  IntersectionZoneCollision: '/api/IntersectionZoneCollision',
  IntersectionZoneVehiclePriority: '/api/IntersectionZoneVehiclePriority',
  InternalTours: '/api/internaltours',
  LIF: '/api/lif',
  PreviewUpdateLif: '/api/lif/compareLif',
  MapData: '/api/vehiclemapdata',
  Maps: '/api/Maps',
  MassCommand: '/api/MassCommands',
  Mappings: '/api/to-mappings',
  MissionArchiveFailures: '/api/missionArchive/failures',
  MissionArchiveMissionReports: '/api/missionArchive/missionReports',
  MissionArchiveFailuresLocation: '/api/missionArchive/locations',
  MissionTrace: '/api/MissionTrace',
  MissionTraceArchive: '/api/missionArchive/missionTraceArchive',
  MissionTraceFailureArchive: '/api/missionArchive',
  MissionTraceLogs: '/api/MissionTraceLogs',
  NavigationLayers: '/api/NavigationLayers',
  NodeGroups: '/api/nodeGroups',
  Nodes: '/api/nodes',
  NotificationMappings: '/api/notification-mappings',
  OPCUADevice: '/api/v1',
  Organization: '/api/Organization',
  PathPlanner: '/api/PathPlanner',
  PillarsGrid: '/api/pillarsGrid',
  PsaNodeMappings: '/api/psanodemappings',
  POIGroups: '/api/PoiGroups',
  POIs: '/api/Pois',
  AssignableWaypoints: '/api/AssignableWaypoints',
  LoadTypes: '/api/load-types',
  PredictedCollision: '/api/PredictedCollision',
  ProcessChainGroupTraces: '/api/ProcessChainGroupTraces',
  ProcessChainGroups: '/api/ProcessChainGroups',
  ProcessChainTrace: '/api/ProcessChainTrace',
  ProcessChains: '/api/ProcessChains',
  Reports: '/api/Reports',
  Role: '/api/Role',
  RouteConfigurations: '/api/routeConfigurations',
  Rules: '/api/rules',
  SensorLookupMappings: '/api/pkt/SensorLookupMappings',
  Settings: '/api/v2/settings',
  SoftwareUpdateDispatch: '/api/SoftwareUpdateDispatch',
  SoftwareUpdateDispatchRetry: '/api/SoftwareUpdateDispatch/Retry',
  SoftwareUpdates: '/api/SoftwareUpdates',
  TuggerMissions: '/api/missionArchive/tuggerMissions',
  TourChainConfigurations: '/api/tourchainconfigurations',
  TourLog: '/api/tourLog',
  TourRetry: '/api/retryTour',
  TourTraces: '/api/missionTraces/tours',
  Tours: '/api/tours',
  TourChains: '/api/tourChains',
  TransportOrderHistoryFilter: '/api/orderhistory/filter',
  TransportOrders: '/api/TransportOrders',
  User: '/api/v2/user',
  UserSettings: '/api/v2/user/settings',
  VehicleCancelShutdown: '/api/Vehicles/cancelshutdown',
  VehicleCommands: '/api/VehicleCommands',
  VehicleMaintenanceMode: '/api/VehicleMaintenanceMode',
  VehicleShutdownAll: '/api/Vehicles/shutdownallvehicles',
  VehicleShutdownStatus: '/api/Vehicles/shutdownstatus',
  Vehicles: '/api/Vehicles',
  ZoneMembership: '/api/ZoneMembership',
  ZoneSet: '/api/zonesets',
  Zones: '/api/zones',
} as const;
